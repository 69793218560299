
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'
  import { Resource } from '@/entities/public'
  import { capitalize } from '@/utils/general'
  import { formFilter, insertInput, updateInput } from '@/graphql/generated-types'
  import { Link } from '@/store/app/state'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { RULES } from '@/components/forms'

@Component({
  computed: {
    ...mapGetters('resources', ['getForm', 'links']),
  },
  methods: {
    ...mapActions('resources/form', ['fetchData', 'pushData', 'closeLead']),
    ...mapActions('resources', ['refresh']),
  },
})
  export default class ModelDialog extends Vue {
  $refs!: {
    formRemove: HTMLFormElement;
  };

  @Prop({ type: Number }) id: number
  @Prop({ type: String, required: true }) model!: string
  @Prop({ type: Boolean, default: false }) anulate!: boolean

  fetchData!: (payload: { query: Query, filter?: formFilter }) => Promise<any>
  getForm!: (model: string, slug?: string) => Resource
  _resource!: Resource;
  open = true
  closeLead!: (payload: { idLead: number, idClosingReason: number }) => Promise<void>
  refresh!: (payload: { force?: boolean }) => Promise<any>
  pushData!: (payload: { model: string, fields?: insertInput | updateInput }) => Promise<any>
  links!: Array<Link>;
  closingReason = []
  valid = false
  reasonType = -1
  rule = RULES.isRequired
  loading = false

  async mounted () {
    if (this.$refs.formRemove) {
      this.$refs.formRemove.reset()
    }

    const leadInfo = (await this.fetchData({
      query: { name: 'find', model: 'Lead' },
      filter: { id: { _eq: this.id } },
      force: true,
    }))[0]

    const status = ['sale', 'renewal', 'financed_sale', 'financed_renovation']
    const isNotSell = !status.includes(leadInfo?.pipeline?.name)

    const exclude = ['withdrawn_consignment']
    if (isNotSell) {
      exclude.push('not_qualify_financing', 'vehicle_not_available')
    }

    const filter = {
      _and: [
        {
          status: {
            _and: [
              { status: { name: { _eq: 'lost' } } },
              { process: { table_name: { _eq: 'lead' } } },
            ],
          },
        },
        { type: { name: { _nin: exclude } } },
      ],
    }

    this.closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason', order: { type: { description: 'asc' } } },
      filter,
    })
  }

  get closingReasonType () {
    if (!this.closingReason.length) return []

    return this.closingReason.map(reason => {
      return {
        id: reason.id,
        description: reason?.description || reason.type.description,
      }
    })
  }

  get resource () {
    const { _resource } = this
    if (_resource) return _resource

    const { model } = this

    return this._resource = this.getForm(capitalize(model))
  }

  get title () {
    const { resource: { name }, anulate } = this

    if (anulate) return 'abono'
    const title = name.toLowerCase()

    const isPlural = title.endsWith('s')
    return isPlural ? title.slice(0, -1) : title
  }

  get buildTitle () {
    const { title, anulate } = this

    if (anulate) return `¿Está seguro de que quiere ANULAR este abono?`

    return `¿Está seguro de que quieres CERRAR este ${title}?`
  }

  get alertAction () {
    return '¡Esta acción es irreversible!'
  }

  async submitPayment () {
    const { id } = this
    this.loading = true
    const closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { status: { process: { table_name: { _eq: 'payment' } } } },
        ],
      },
    })

    const paymentStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }] },
    })
    const closed = paymentStatus.filter(status => status.status.name === 'closed')

    await this.pushData({
      model: 'Payment',
      fields: {
        id,
        id_process_status: closed[0].id,
        id_closing_reason: closingReason.find(reason => reason.name === 'canceled').id,
      },
    })

    await this.closeForm()
  }

  async submit () {
    if (!this.$refs.formRemove.validate()) return
    const { id, reasonType } = this
    this.loading = true
    await this.closeLead({ idLead: id, idClosingReason: reasonType })

    await this.closeForm()
  }

  close () {
    this.open = false
    this.closeForm()
  }

  async closeForm () {
    const { links } = this

    const link = links.find(({ route }) => this.$route.fullPath.includes(route.path))
    await this.refresh({ force: true })
    if (!link) {
      this.$router.back()
      return
    }

    const { route: { path, name } } = link

    this.$router.push(path || { name }).catch(() => {
    })
  }

  get actionName () {
    return this.anulate ? 'Anular' : 'Archivar'
  }
  }
